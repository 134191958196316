const Home = () => import('../../views/home.vue')
const Signup = () => import('../../views/signup.vue')
const PrivacyPolicy = () => import('../../views/pages/privacy_policy.vue')
const TermsAndConditions = () => import('../../views/pages/terms_and_conditions.vue')
const RefundPolicy = () => import('../../views/pages/refund_policy.vue')
const TermsAndConditionsCustomizations = () => import('../../views/pages/terms_and_conditions_customizations.vue')
const OPDMNGT = () => import('../../views/pages/opd_mngt.vue')
const IPDMNGT = () => import('../../views/pages/ipd_mngt.vue')
const APPOINTMENTMNGT = () => import('../../views/pages/appointments_mngt.vue')
const BILLINGMNGT = () => import('../../views/pages/billing_mngt.vue')
const PATIENTMNGT = () => import('../../views/pages/patient_mngt.vue')
const MEDICINEMNGT = () => import('../../views/pages/medicine_mngt.vue')
const USERMNGT = () => import('../../views/pages/user_mngt.vue')
const STATISTICSMNGT = () => import('../../views/pages/statistics_mngt.vue')

const router_path = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: TermsAndConditions
    },
    {
        path: '/refund-policy',
        name: 'refund-policy',
        component: RefundPolicy
    },
    {
        path: '/tnc-customization',
        name: 'tnc-customization',
        component: TermsAndConditionsCustomizations
    },
    {
        path: '/opd-management',
        name: 'opd-mngt',
        component: OPDMNGT
    },
    {
        path: '/ipd-management',
        name: 'ipd-mngt',
        component: IPDMNGT
    },
    {
        path: '/appointment-management',
        name: 'appointments_mngt',
        component: APPOINTMENTMNGT
    },
    {
        path: '/billing-management',
        name: 'billing_mngt',
        component: BILLINGMNGT
    },
    {
        path: '/patient-management',
        name: 'patient_mngt',
        component: PATIENTMNGT
    },
    {
        path: '/medicine-management',
        name: 'medicine_mngt',
        component: MEDICINEMNGT
    },
    {
        path: '/statistics-management',
        name: 'statistics_mngt',
        component: STATISTICSMNGT
    },
    {
        path: '/user-management',
        name: 'user_mngt',
        component: USERMNGT
    },
]

export default router_path;
